import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import AddCatering from '@common/dialog/AddCatering';
import EditLuggage from '@common/dialog/EditLuggage';
import { USER_LEVEL } from '@constant/user_level';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { getName, tooltipPassportStatusData } from '@helper/stringFunction';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';
import { Status } from './Status';
import {
  AvatarWrapper,
  ClientItemWrapper,
  Badge,
  MenuWrapper,
  TableWrapper
} from './styled';
import { userId } from '@user/selector/userInfoSelector';

export default function PassengerItem({ data }: { data: Record<string, any> }) {
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams();
  const anchorPopover = useRef<HTMLDivElement>(null);
  const { activeLeg, legData, flightData } = useFlightDetail();
  const { customer: mainPoc, customer_2: secondPoc } = flightData;
  const myId = useSelector(userId);
  const isMyLeg = [mainPoc?.id, secondPoc?.id].includes(myId);
  const dispatch = useDispatch();
  const { customer, id: paxId, deleting } = data;

  if (isEmpty(customer)) return null;

  const {
    status = 1,
    firstName,
    lastName,
    email,
    luggage,
    caterings,
    allergies,
    customerTypeId,
    is_me,
    customerId
  } = customer;

  const clientName = getName(customer);
  const showEmail = firstName || lastName;

  const tooltipLuggage = () => {
    if (!luggage.length) return 'No luggage added';
    return (
      <ul style={{ paddingLeft: '24px' }}>
        {luggage.map((item, index) => (
          <li key={index}>{`${item?.type?.luggage_name} (${item?.weight} kg): ${
            item?.checkedIn ? 'Checked in' : 'Not checked in'
          } - ${item?.loaded ? 'Loaded' : 'Not loaded'}`}</li>
        ))}
      </ul>
    );
  };

  const tooltipCatering = () => {
    if (!caterings.length) return 'No catering added';
    return (
      <ul style={{ paddingLeft: '24px' }}>
        {caterings.map((item, index) => (
          <li key={index}>{item?.catering_name}</li>
        ))}
      </ul>
    );
  };

  const tooltipAllergy = () => {
    if (!allergies.length) return 'No food allergy added';
    return (
      <ul style={{ paddingLeft: '24px' }}>
        {allergies.map((item, index) => (
          <li key={index}>{item.allergyName}</li>
        ))}
      </ul>
    );
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editLuggage = () => {
    setOpen(false);
    DialogJSX.open({
      component: EditLuggage,
      props: {
        title: 'Add luggage',
        cancelButtonLabel: 'Close',
        paxId: data.id
      },
      inMobile: 'full'
    });
  };

  const addMyCatering = () => {
    setOpen(false);
    DialogJSX.open({
      component: AddCatering,
      props: {
        title: 'Add my catering',
        cancelButtonLabel: 'Close',
        submitButtonLabel: 'Confirm',
        activeLeg,
        flightId: id,
        customer
      },
      inMobile: 'full'
    });
  };

  const editInfoGuest = () => {
    setOpen(false);
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/offline-details.php?pax=${customer.signup_token}`;
  };

  const onRemove = () => {
    setOpen(false);
    dispatch({
      type: '@flight/deletePax',
      payload: {
        flightLines_Id: legData.id,
        customerId,
        paxId,
        is_me,
        clientName,
        activeLeg
      }
    });
  };

  return (
    <ClientItemWrapper deleting={deleting}>
      <AvatarWrapper>
        <Avatar
          className="fs-exclude"
          variant="square"
          sx={{
            width: 64,
            height: 64,
            bgcolor: 'neutral.light2'
          }}
          src={customer?.onfido_image}
        />
        {customerTypeId === 4 ? <Badge>GUEST</Badge> : null}
        {is_me ? <Badge is_me>MYSELF</Badge> : null}
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid item xs={4} sx={{ pl: 1 }}>
            <Box
              className="flex-column"
              sx={{ alignItems: 'flex-start', minWidth: 0, pr: 2 }}
            >
              <TruncatedText
                color="neutral.black"
                variant="subtitle3"
                noWrap
                sx={{ pr: 2 }}
              >
                {clientName}
              </TruncatedText>
              {showEmail ? (
                <TruncatedText color="neutral.dark2" variant="caption" noWrap>
                  {email}
                </TruncatedText>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={2}>
            {customerTypeId === 4 ? (
              <Status type={'none'} titleTooltip="No passport" />
            ) : (
              <Tooltip title={tooltipPassportStatusData(status).text} arrow>
                <Icon
                  icon={tooltipPassportStatusData(status).icon}
                  size="lg"
                  color={tooltipPassportStatusData(status).color}
                  haveBackground
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={1.5}>
            <Status
              type={luggage?.length ? 'success' : 'none'}
              titleTooltip={<React.Fragment>{tooltipLuggage()}</React.Fragment>}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Status
              type={caterings.length ? 'success' : 'none'}
              titleTooltip={
                <React.Fragment>{tooltipCatering()}</React.Fragment>
              }
            />
          </Grid>
          <Grid item xs={1.5}>
            <Status
              type={allergies?.length ? 'allergy' : 'none'}
              titleTooltip={<React.Fragment>{tooltipAllergy()}</React.Fragment>}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Status type={'none'} titleTooltip="Not checked" />
          </Grid>
        </Grid>
      </TableWrapper>

      <AuthorizedComponent
        requiredRoles={[
          USER_LEVEL.SUPERUSER,
          USER_LEVEL.BROKER,
          USER_LEVEL.CLIENT
        ]}
      >
        <MenuWrapper ref={anchorPopover} onClick={handleClick}>
          <IconButton
            sx={{
              mr: 3,
              ':hover': {
                '.icon': {
                  color: 'primary.main',
                  transition: 'all 0.2s ease-in-out'
                }
              }
            }}
          >
            <Icon
              icon="icon-dot-menu"
              sx={{ color: 'neutral.dark1' }}
              size="md"
            />
          </IconButton>
        </MenuWrapper>
      </AuthorizedComponent>
      <Menu
        open={Boolean(open)}
        anchorEl={anchorPopover.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: -1.5 }}
      >
        <AuthorizedComponent
          requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.BROKER]}
        >
          {customerTypeId === 4 && legData?.cateringOptions?.length ? (
            <MenuItem onClick={addMyCatering} disableRipple>
              <Typography color="neutral.dark3" variant="body2">
                {caterings.length > 0 ? 'Edit catering' : 'Add catering'}
              </Typography>
            </MenuItem>
          ) : null}
        </AuthorizedComponent>
        <AuthorizedComponent
          requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.CLIENT]}
        >
          <MenuItem onClick={editLuggage} disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              {is_me ? 'Add luggage' : 'Add luggage'}
            </Typography>
          </MenuItem>
        </AuthorizedComponent>
        {customerTypeId === 4 ? (
          <MenuItem onClick={editInfoGuest} disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              Edit info
            </Typography>
          </MenuItem>
        ) : null}
        {customerTypeId === 4 && isMyLeg && legData?.cateringOptions?.length ? (
          <MenuItem onClick={addMyCatering} disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              {caterings.length > 0 ? 'Edit catering' : 'Add catering'}
            </Typography>
          </MenuItem>
        ) : null}
        {is_me && legData?.cateringOptions?.length ? (
          <MenuItem onClick={addMyCatering} disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              {caterings.length > 0 ? 'Edit catering' : 'Add catering'}
            </Typography>
          </MenuItem>
        ) : null}
        <AuthorizedComponent
          requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.BROKER]}
        >
          <MenuItem onClick={onRemove} disableRipple>
            <Typography color="danger.main" variant="body2">
              {'Remove passenger'}
            </Typography>
          </MenuItem>
        </AuthorizedComponent>
        {isMyLeg ? (
          <AuthorizedComponent requiredRoles={[USER_LEVEL.CLIENT]}>
            <MenuItem onClick={onRemove} disableRipple>
              <Typography color="danger.main" variant="body2">
                {is_me ? 'Remove myself' : 'Remove passenger'}
              </Typography>
            </MenuItem>
          </AuthorizedComponent>
        ) : null}
      </Menu>
    </ClientItemWrapper>
  );
}

import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { getName } from '@helper/stringFunction';
import { Box, Typography } from '@mui/material';
import { Column, ContentWrapper, Divider, IconWrapper } from './styled';

const AircraftSection = () => {
  const { legData, flightData } = useFlightDetail();
  const { parent: broker, assignee } = flightData;
  const { aircraftReg, operator } = legData;
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon
          className="icon-rotate-90"
          icon="icon-airplane-above-fill"
          size="lg"
          sx={{ color: 'primary.dark1' }}
        />
        <Typography variant="head3Mons" color="primary.dark2">
          Aircraft & broker
        </Typography>
      </Box>
      <ContentWrapper>
        <Column>
          <IconWrapper
            sx={{
              backgroundColor: 'neutral.white'
            }}
          >
            <Icon
              size="lg"
              icon="icon-airplane-in-flight-fill"
              sx={{ color: 'primary.light1' }}
            />
          </IconWrapper>
          <Box className="flex-column" sx={{ alignItems: 'flex-start' }}>
            <TruncatedText variant="subtitle2" color="neutral.dark3">
              Aircraft
            </TruncatedText>
            <TruncatedText variant="subtitle1" color="primary.dark1">
              <b>{aircraftReg}</b>
            </TruncatedText>
          </Box>
          <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
            <TruncatedText
              variant="subtitle2"
              color="neutral.dark3"
              className="text-end"
            >
              Operated by
            </TruncatedText>
            <TruncatedText
              variant="subtitle1"
              color="primary.dark1"
              className="text-end"
            >
              <b>{operator?.organisation_name ?? 'TBD'}</b>
            </TruncatedText>
          </Box>
        </Column>
        <Divider />
        <Column>
          <IconWrapper
            sx={{
              backgroundColor: 'neutral.white'
            }}
          >
            <Icon
              icon="icon-broker"
              size="lg"
              sx={{ color: 'primary.light1' }}
            />
          </IconWrapper>
          <Box className="flex-column" sx={{ alignItems: 'flex-start' }}>
            <TruncatedText variant="subtitle2" color="neutral.dark3">
              Broker
            </TruncatedText>
            <TruncatedText variant="subtitle1" color="primary.dark1">
              <b>{broker?.organisation_name ?? 'TBD'}</b>
            </TruncatedText>
          </Box>
          <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
            <TruncatedText
              variant="subtitle2"
              color="neutral.dark3"
              className="text-end"
            >
              Invite
            </TruncatedText>
            <TruncatedText
              variant="subtitle1"
              color={assignee ? 'primary.dark1' : 'neutral.dark1'}
              className="text-end"
            >
              <b>{getName(assignee) ?? 'No broker assigned'}</b>
            </TruncatedText>
          </Box>
        </Column>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default AircraftSection;

import { userInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import router from '@utils/route/router';
import { enqueueSnackbar } from 'notistack';
import { put, select, takeEvery } from 'redux-saga/effects';

export function* createFlight(action: any) {
  const userLevel = yield select(userLevelSelector);

  const prefix = [1, 5].includes(userLevel) ? 'flights' : 'index';

  router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}/${prefix}.php?new`;
}

export function* addLuggagePax(action: any): Record<string, any> {
  const { data, paxId, setSubmitting } = action.payload;
  try {
    setSubmitting(true);
    const response = yield userInstance.request({
      url: '/flight-luggage',
      method: 'POST',
      data
    });

    const returnData = response?.data?.data;
    setSubmitting(false);
    yield put({
      type: 'flight/addLuggage',
      payload: {
        paxId,
        data: returnData
      }
    });
    if (returnData) {
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* selectCatering(action: any): Record<string, any> {
  const { data, setSubmitting, flightLineId, closeDialog, customerId } =
    action.payload;
  try {
    setSubmitting(true);
    const response = yield userInstance.request({
      url: '/flight-catering',
      method: 'POST',
      data: {
        ids: data,
        flightLines_Id: flightLineId,
        customerId: customerId
      }
    });

    const returnData = response?.data?.data;
    setSubmitting(false);
    yield put({
      type: 'flight/updatePax',
      payload: {
        data: returnData
      }
    });
    if (returnData) {
      closeDialog();
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* addYourself(action: any): Record<string, any> {
  const { flightLineId, activeLeg } = action.payload;
  try {
    const ok = yield DialogJSX.confirm({
      props: {
        title: `Add yourself to Leg ${activeLeg + 1}`,
        content: `Would you like to add yourself to Leg ${activeLeg + 1}?`,
        submitButtonLabel: 'Confirm',
        cancelButtonLabel: 'Close',
        submitButtonColor: 'secondary'
      },
      inMobile: 'full'
    });
    if (ok) {
      const response = yield userInstance.request({
        url: '/flight-passenger-add-self',
        method: 'POST',
        data: {
          flight_line_id: flightLineId
        }
      });

      const returnData = response?.data?.data;
      yield put({
        type: 'flight/addPax',
        payload: {
          data: returnData
        }
      });
      if (returnData) {
        yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
      }
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* invitePax(action: any): Record<string, any> {
  const { data } = action.payload;
  const { setSubmitting, closeDialog, formik } = action.meta;

  try {
    setSubmitting(true);
    const response = yield userInstance.request({
      url: '/flight-passenger-invite',
      method: 'POST',
      data
    });

    const returnData = response?.data?.data;

    if (returnData) {
      closeDialog();
      setSubmitting(false);
      yield put({
        type: 'flight/refreshPaging',
        payload: {
          resources: ['passenger']
        }
      });
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    setSubmitting(false);
    yield handleActionError(err, formik);
  }
}

export function* deleteLuggagePax(action: any): Record<string, any> {
  const { lugId, setSubmitting } = action.payload;

  try {
    setSubmitting(true);
    const response = yield userInstance.request({
      url: `/flight-luggage/${lugId}`,
      method: 'DELETE'
    });

    const returnData = response?.data?.data;
    setSubmitting(false);
    yield put({
      type: 'flight/deleteLuggage',
      payload: {
        luggageId: lugId
      }
    });
    if (returnData) {
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* deletePax(action: any): Record<string, any> {
  const { customerId, flightLines_Id, paxId, is_me, clientName, activeLeg } =
    action.payload;

  try {
    const ok = yield DialogJSX.confirm({
      props: {
        title: `Remove passenger - Leg ${activeLeg + 1}`,
        content: (
          <span>
            Are you sure you want to remove{' '}
            <b>{is_me ? 'yourself' : clientName}</b> as a passenger ?
          </span>
        ),
        submitButtonLabel: 'Yes, I want to remove this',
        cancelButtonLabel: 'Close'
      },
      inMobile: 'full'
    });
    if (ok) {
      yield put({
        type: 'flight/updateEntity',
        payload: {
          path: ['passenger', 'passenger', paxId],
          data: { deleting: true }
        }
      });
      const response = yield userInstance.request({
        url: '/flight-passenger-remove',
        method: 'DELETE',
        data: {
          flightLines_Id,
          customerId: customerId
        }
      });

      const returnData = response?.data?.data;
      yield put({
        type: 'flight/deletePax',
        payload: {
          paxId,
          flightLines_Id
        }
      });
      if (returnData) {
        yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
      }
    }
  } catch (err) {
    yield put({
      type: 'flight/updateEntity',
      payload: {
        path: ['passenger', 'passenger', paxId],
        data: { deleting: false }
      }
    });
    yield handleActionError(err);
  }
}

const sagaEffect = [
  takeEvery('@flight/creteFlight', createFlight),
  takeEvery('@flight/addLuggagePax', addLuggagePax),
  takeEvery('@flight/deleteLuggagePax', deleteLuggagePax),
  takeEvery('@flight/selectCatering', selectCatering),
  takeEvery('@flight/addYourself', addYourself),
  takeEvery('@flight/deletePax', deletePax),
  takeEvery('@flight/invitePax', invitePax)
];

export default sagaEffect;

import { USER_LEVEL } from '@constant/user_level';
import { Box, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useAirportCode from 'src/hooks/useAirportCode';
import LegStatus from './LegStatus';
import {
  CurvedLine,
  CurvedLineSVG,
  EndPoint,
  EndPointIcon,
  FlightLineInfo,
  StartPoint
} from './styled';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';

const LegInfo = ({ handleChange }) => {
  const airportCodeConfig = useAirportCode();
  const { activeLeg, legData, flight_lines = [1] } = useFlightDetail();
  const userLevel = useSelector(userLevelSelector);
  const { id } = useParams();

  const serviceEnable = [USER_LEVEL.SUPERUSER, USER_LEVEL.OPERATOR].includes(
    userLevel
  );
  const prefix = userLevel === USER_LEVEL.SUPERUSER ? 'flights' : 'index';
  const subfix = userLevel === USER_LEVEL.SUPERUSER ? 'input' : 'edit';
  const onComingsoon = () => {
    DialogJSX.comingsoon();
  };

  const onInput = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/${prefix}.php?${subfix}=${id}`;
  };

  const onCatering = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/${prefix}.php?catering=${legData?.id}`;
  };
  return (
    <>
      <Tabs
        value={activeLeg}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {flight_lines?.map((item, index) => {
          return <Tab disableRipple key={index} label={`Leg ${index + 1}`} />;
        })}
      </Tabs>

      <FlightLineInfo>
        <Box className="flex-column" sx={{ alignItems: 'flex-start', flex: 3 }}>
          <Typography variant="subtitle2" color="neutral.dark3">
            {legData?.departTime ?? 'TBD'} (L)
          </Typography>
          <Typography variant="body2" color="neutral.dark1">
            {legData?.departDateShort ?? 'TBD'}
          </Typography>

          <Tooltip title={legData?.depart_airport.name} arrow>
            <Typography
              variant="head3Mons"
              component="div"
              sx={{ mt: 0.5, '&:hover': { color: 'primary.dark1' } }}
              color="neutral.dark3"
            >
              {legData?.depart_airport[airportCodeConfig]}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: '152px',
            height: '100%'
          }}
        >
          <StartPoint />
          <CurvedLine>
            <img src="/images/air_stroked.png" alt="" />
            <CurvedLineSVG />
          </CurvedLine>
          <EndPoint>
            <EndPointIcon />
          </EndPoint>
        </Box>
        <Box className="flex-column" sx={{ alignItems: 'flex-end', flex: 3 }}>
          <Typography variant="subtitle2" color="neutral.dark3">
            {legData?.arriveTime ?? 'TBD'} (L)
          </Typography>
          <Typography variant="body2" color="neutral.dark1">
            {legData?.arriveDateShort ?? 'TBD'}
          </Typography>
          <Tooltip title={legData?.arrive_airport.name} arrow>
            <Typography
              variant="head3Mons"
              component="div"
              sx={{ mt: 0.5, '&:hover': { color: 'primary.dark1' } }}
              color="neutral.dark3"
            >
              {legData?.arrive_airport[airportCodeConfig]}
            </Typography>
          </Tooltip>
        </Box>
      </FlightLineInfo>
      <Box className="flex-column" sx={{ gap: 2, mt: 3 }}>
        <AuthorizedComponent
          requiredRoles={[USER_LEVEL.CLIENT, USER_LEVEL.BROKER]}
        >
          <LegStatus
            buttonIcon={
              legData?.directionAddress?.length ? 'icon-Map-filled' : null
            }
            complete={!!legData?.directionAddress?.length}
            onClick={onComingsoon}
          />
        </AuthorizedComponent>
        <AuthorizedComponent
          requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.OPERATOR]}
        >
          <LegStatus
            complete={!!legData?.directionAddress?.length}
            onClick={onInput}
          />
        </AuthorizedComponent>

        <LegStatus
          title="Hotels"
          icon="icon-Hotel"
          buttonIcon={serviceEnable ? 'icon-Pencil-filled' : null}
          subtitle={legData?.hotel}
          complete={legData?.hotelComplete}
          onClick={onInput}
        />
        <LegStatus
          title="Transport"
          icon="icon-car-fill"
          buttonIcon={serviceEnable ? 'icon-Pencil-filled' : null}
          complete={legData?.transportComplete}
          onClick={onInput}
        />
        <LegStatus
          title="Catering"
          icon="icon-catering-2"
          buttonIcon={serviceEnable ? 'icon-Pencil-filled' : null}
          complete={legData?.cateringOptions?.length > 0}
          onClick={onCatering}
        />
      </Box>
    </>
  );
};

export default LegInfo;

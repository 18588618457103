import Breadcrumbs from '@common/components/Breadcrumbs';
import Icon from '@common/components/Icon';
import { USER_LEVEL } from '@constant/user_level';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { Box, Button, Typography } from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { useParams } from 'react-router';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';

const FlightDetailHeader = ({ handlePrint }) => {
  const { id } = useParams();
  const { flight_code } = useFlightDetail().flightData;
  const onEdit = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?edit=${id}`;
  };
  const onInput = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?input=${id}`;
  };

  const onOperatorInput = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/index.php?edit=${id}`;
  };

  const onPrint = () => {
    DialogJSX.comingsoon();
  };
  return (
    <>
      <Box className="no-print" sx={{ backgroundColor: 'white', px: 8, py: 3 }}>
        <Breadcrumbs
          data={[
            { to: '/flights', label: 'Flights' },
            { label: 'Flight details' }
          ]}
        />
      </Box>
      <Box
        className="flex-center-rows flex-space-between-row"
        sx={{ px: 8, pt: 3, pb: 4 }}
      >
        <Typography variant="head2Mons" color="primary.dark3">
          Flight details - {flight_code}
        </Typography>
        <Box className="flex-center-rows no-print" sx={{ gap: 2 }}>
          <AuthorizedComponent requiredRoles={[USER_LEVEL.SUPERUSER]}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              startIcon={
                <Icon
                  icon="icon-Document-add-filled"
                  sx={{ color: 'primary.dark2' }}
                />
              }
              onClick={onInput}
            >
              Fill operator details
            </Button>
          </AuthorizedComponent>
          <AuthorizedComponent requiredRoles={[USER_LEVEL.OPERATOR]}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              startIcon={
                <Icon
                  icon="icon-Document-add-filled"
                  sx={{ color: 'primary.dark2' }}
                />
              }
              onClick={onOperatorInput}
            >
              Fill operator details
            </Button>
          </AuthorizedComponent>
          <AuthorizedComponent
            requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.BROKER]}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              startIcon={
                <Icon
                  icon="icon-Pencil-alt-filled"
                  sx={{ color: 'primary.dark2' }}
                />
              }
              onClick={onEdit}
            >
              Edit flight
            </Button>
          </AuthorizedComponent>
          <AuthorizedComponent
            requiredRoles={[
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.BROKER,
              USER_LEVEL.OPERATOR
            ]}
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={
                <Icon
                  icon="icon-printer-fill"
                  sx={{ color: 'neutral.white' }}
                />
              }
              onClick={handlePrint}
            >
              Print PDF
            </Button>
          </AuthorizedComponent>
          <AuthorizedComponent requiredRoles={[USER_LEVEL.CLIENT]}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={
                <Icon
                  icon="icon-printer-fill"
                  sx={{ color: 'neutral.white' }}
                />
              }
              onClick={onPrint}
            >
              Print boarding pass
            </Button>
          </AuthorizedComponent>
        </Box>
      </Box>
    </>
  );
};

export default FlightDetailHeader;

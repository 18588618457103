import Icon from '@common/components/Icon';
import { CheckboxForm } from '@common/formElement';
import RadioGroupForm from '@common/formElement/RadioGroupGrom';
import TextFieldForm from '@common/formElement/TextFieldForm';
import { entitySelector } from '@core/selector/entitySelector';
import { AddPaxSchema } from '@flight/helper/schema';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../ActionFooter';
import { Instruction, Wrapper } from './styled';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { USER_LEVEL } from '@constant/user_level';

export default function AddPassenger(props: any) {
  const {
    closeDialog,
    cancelButtonLabel,
    submitButtonLabel,
    setForceOpen,
    flightId
  } = props;
  const [openInstruction, setOpenInstruction] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const flightData = useSelector(state =>
    entitySelector(state, 'flight', flightId, 'flight')
  );
  const flightLineIds: number[] =
    flightData?.flight_lines.map(item => item.id) ?? [];
  useEffect(() => {
    setForceOpen(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    const filteredLeg = value.legs.filter(item => item !== 0);
    dispatch({
      type: '@flight/invitePax',
      payload: {
        data: { ...value, flightHead_Id: flightId, legs: filteredLeg }
      },
      meta: { setSubmitting, closeDialog, formik }
    });
  };
  const userLevel = useSelector(userLevelSelector);

  return (
    <Wrapper sx={{ width: '600px' }}>
      <Box>
        <Formik
          initialValues={{
            email: '',
            firstName: null,
            lastName: null,
            underAge: 0,
            type: userLevel === USER_LEVEL.CLIENT ? 'guest' : 'email',
            legs: flightLineIds,
            pet: 0
          }}
          onSubmit={handleSubmit}
          validationSchema={AddPaxSchema}
          validateOnMount
        >
          {({ values, initialValues, isValid, errors }) => {
            return (
              <Form>
                <Box sx={{ overflow: 'auto', maxHeight: '550px' }}>
                  {userLevel === USER_LEVEL.CLIENT ? null : (
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        backgroundColor: 'primary.light3',
                        borderRadius: 2,
                        maxWidth: '536px',
                        mb: 3
                      }}
                    >
                      <Box
                        className="flex-center-rows"
                        sx={{ cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => setOpenInstruction(prev => !prev)}
                      >
                        <Icon
                          icon="icon-question-fill"
                          color="inherit"
                          sx={{ color: 'primary.main', mr: 1 }}
                        />
                        <Typography variant="body2" color="primary.dark1">
                          How does this work?
                        </Typography>
                      </Box>
                      <Typography variant="caption" color="primary.dark3">
                        <Instruction open={openInstruction}>
                          <span>
                            If there is an AvıoNexus account associated with
                            that email:
                          </span>
                          <ul>
                            <li>The user will be added to this flight.</li>
                            <li>
                              The user will be notified you have added them to
                              this flight.
                            </li>
                          </ul>
                          <span>
                            If there is <b>not</b> an AvıoNexus account
                            associated with that email:
                          </span>
                          <ul>
                            <li>
                              The user will be added to this flight as pending.
                            </li>
                            <li>
                              The system will email the client with a signup
                              link informing them you have invited them to the
                              system to be a passenger on your flight.
                            </li>
                            <li>
                              You will be notified when they sign up and they
                              will no longer show as pending on your passenger
                              list.
                            </li>
                          </ul>
                        </Instruction>
                      </Typography>
                    </Paper>
                  )}
                  <Typography
                    component="div"
                    variant="body1"
                    color="neutral.dark3"
                  >
                    Please select a method of adding a passenger below.
                  </Typography>
                  <RadioGroupForm
                    name="type"
                    config={{
                      inline: true,
                      margin: 'dense',
                      sxWrapper: { gap: 8, mb: 0.5 },
                      options:
                        userLevel === USER_LEVEL.CLIENT
                          ? [
                              {
                                value: 'guest',
                                label: 'Guest',
                                hint: 'The passenger will not be able to log in and must managed by you or the point of contact.'
                              }
                            ]
                          : [
                              { value: 'email', label: 'Email' },
                              {
                                value: 'guest',
                                label: 'Guest',
                                hint: 'The passenger will not be able to log in and must managed by you or the point of contact.'
                              }
                            ]
                    }}
                  />
                  <TextFieldForm
                    name="email"
                    config={{
                      label: 'Email address',
                      fullWidth: true,
                      required: true
                    }}
                    initialValues={initialValues}
                  />
                  {values.type === 'email' ? null : (
                    <Box sx={{ mt: 1 }}>
                      <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={{ xs: 1, sm: 0 }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextFieldForm
                            name="firstName"
                            config={{
                              label: 'First name',
                              required: true,

                              fullWidth: true
                            }}
                            initialValues={initialValues}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldForm
                            name="lastName"
                            config={{
                              label: 'Last name',
                              required: true,
                              fullWidth: true
                            }}
                            initialValues={initialValues}
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ ml: 1.5, mt: 2 }}>
                        <CheckboxForm
                          name={'underAge'}
                          config={{
                            size: 'small',
                            required: true,
                            label:
                              "Passenger is under 13 and has guardian's consent"
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Typography
                    component="div"
                    variant="body1"
                    color="neutral.dark3"
                    sx={{ mt: 3 }}
                  >
                    Leg selection
                  </Typography>
                  <Grid container rowSpacing={2} sx={{ mt: 0, px: 1.5 }}>
                    {flightLineIds.map((item: number, index: number) => {
                      return (
                        <Grid item xs={2.4} key={index}>
                          <CheckboxForm
                            name={`legs[${index}]`}
                            config={{
                              size: 'small',
                              required: true,
                              checkedValue: item,
                              label: `Leg ${index + 1}`
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box sx={{ mt: 1 }} className="errorMessage">
                    {errors.legs}
                  </Box>
                  <Typography
                    component="div"
                    variant="body1"
                    color="neutral.dark3"
                    sx={{ mt: 3 }}
                  >
                    Pets
                  </Typography>
                  <Box sx={{ ml: 1.5, mt: 2 }}>
                    <CheckboxForm
                      name={'pet'}
                      config={{
                        size: 'small',
                        required: true,
                        label: 'Passenger will be bringing a pet'
                      }}
                    />
                  </Box>
                </Box>
                <Footer
                  cancelButtonLabel={cancelButtonLabel}
                  submitButtonLabel={submitButtonLabel}
                  closeDialog={closeDialog}
                  disabledSubmit={!isValid || isSubmitting}
                  isSubmitting={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Wrapper>
  );
}

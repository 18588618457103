import Icon from '@common/components/Icon';
import PassengerLuggage from '@common/dialog/PassengerLuggage';
import { USER_LEVEL } from '@constant/user_level';
import { getTotalLuggageWeight } from '@helper/stringFunction';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';
import {
  Column,
  ContentWrapper,
  Divider,
  IconWrapper,
  StaticWrapper
} from './styled';
import PassengerCatering from '@common/dialog/PassengerCatering';
import AddCatering from '@common/dialog/AddCatering';
import { useParams } from 'react-router';
import EditLuggage from '@common/dialog/EditLuggage';
import AddPassenger from '@common/dialog/AddPassenger';
import { useDispatch, useSelector } from 'react-redux';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { userId, userLevelSelector } from '@user/selector/userInfoSelector';

const PassengerActionHeader = ({ passenger, loading }) => {
  const { id } = useParams();
  const { activeLeg, legData, flightData } = useFlightDetail();
  const { customer: mainPoc, customer_2: secondPoc } = flightData;
  const userLevel = useSelector(userLevelSelector);

  const { id: flightLineId } = legData;
  const totalLuggage = getTotalLuggageWeight(passenger);
  const cateringSelected = passenger.filter(
    item => item?.customer?.caterings?.length > 0
  );
  const meInList = passenger.find(
    (item: Record<string, any>) => item?.customer?.is_me
  )?.customer;
  const dispatch = useDispatch();
  const myId = useSelector(userId);
  const isMyLeg = [mainPoc?.id, secondPoc?.id].includes(myId);

  // ACTION BUTTON
  const onRemind = () => {
    DialogJSX.comingsoon();
  };
  const onAddPax = () => {
    DialogJSX.open({
      component: AddPassenger,
      props: {
        title: 'Add passenger',
        submitButtonLabel: 'Confirm',
        cancelButtonLabel: 'Close',
        flightId: id
      },
      inMobile: 'full'
    });
  };

  const addYourself = () => {
    dispatch({
      type: '@flight/addYourself',
      payload: { flightLineId, activeLeg }
    });
  };

  const addMyCatering = () => {
    DialogJSX.open({
      component: AddCatering,
      props: {
        title: 'Add my catering',
        cancelButtonLabel: 'Close',
        submitButtonLabel: 'Confirm',
        activeLeg,
        flightId: id,
        meInList
      },
      inMobile: 'full'
    });
  };

  const addMyLuggage = () => {
    DialogJSX.open({
      component: EditLuggage,
      props: {
        title: 'Add luggage',
        cancelButtonLabel: 'Close',
        paxId: passenger.find(
          (item: Record<string, any>) => item?.customer?.is_me
        ).id
      },
      inMobile: 'full'
    });
  };

  const onViewCatering = () => {
    DialogJSX.open({
      component: PassengerCatering,
      props: {
        title: `Passenger catering choices - Leg ${activeLeg + 1}`,
        cancelButtonLabel: 'Close',
        passenger
      },
      inMobile: 'full'
    });
  };

  const onViewLuggage = () => {
    DialogJSX.open({
      component: PassengerLuggage,
      props: {
        title: `Passenger luggage - Leg ${activeLeg + 1}`,
        cancelButtonLabel: 'Close',
        passenger,
        totalLuggage
      },
      inMobile: 'full'
    });
  };

  return (
    <>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 1 }}>
        <Icon
          icon="icon-address-book-tabs-fill"
          size="lg"
          sx={{ color: 'primary.dark1' }}
        />
        <Typography variant="head3Mons" color="primary.dark2">
          {isMyLeg || userLevel !== USER_LEVEL.CLIENT
            ? 'Passenger list'
            : 'My travel options'}
        </Typography>
        {loading ? null : (
          <Box
            className="flex-center-rows"
            sx={{ flex: 1, justifyContent: 'flex-end', gap: 2 }}
          >
            <AuthorizedComponent
              requiredRoles={[USER_LEVEL.BROKER, USER_LEVEL.SUPERUSER]}
            >
              {passenger?.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon icon="icon-remind" sx={{ color: 'primary.dark2' }} />
                  }
                  onClick={onRemind}
                >
                  Remind passengers to enter details
                </Button>
              ) : null}
            </AuthorizedComponent>
            <AuthorizedComponent requiredRoles={[USER_LEVEL.CLIENT]}>
              {meInList &&
              !meInList?.caterings?.length &&
              legData?.cateringOptions?.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-catering-2"
                      sx={{ color: 'primary.dark2' }}
                      size="sm"
                    />
                  }
                  onClick={addMyCatering}
                >
                  Add my catering
                </Button>
              ) : null}
              {!meInList || meInList?.luggage?.length ? null : (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-Briefcase-filled"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={addMyLuggage}
                >
                  Add my luggage
                </Button>
              )}
              {meInList ? null : (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-Plus-filled"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={addYourself}
                >
                  Add yourself to this leg
                </Button>
              )}
              {meInList && meInList.luggage.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-printer-fill"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={onRemind}
                >
                  Print luggage tag
                </Button>
              ) : null}
            </AuthorizedComponent>
          </Box>
        )}
      </Box>
      {isMyLeg || userLevel !== USER_LEVEL.CLIENT ? (
        <ContentWrapper>
          <StaticWrapper>
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  size="lg"
                  icon="icon-User-group-filled"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  Number of passengers
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>{passenger?.length}</b>
                </Typography>
              </Box>
              <AuthorizedComponent
                requiredRoles={[
                  USER_LEVEL.SUPERUSER,
                  USER_LEVEL.BROKER,
                  USER_LEVEL.CLIENT
                ]}
              >
                <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                  <IconButton color="inherit" onClick={onAddPax}>
                    <Icon icon="icon-Plus-filled" size="sm"></Icon>
                  </IconButton>
                </Box>
              </AuthorizedComponent>
            </Column>
            <Divider />
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  size="lg"
                  icon="icon-Briefcase-filled"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  Total luggage weight
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>{totalLuggage} kg</b>
                </Typography>
              </Box>
              {totalLuggage > 0 ? (
                <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                  <IconButton color="inherit" onClick={onViewLuggage}>
                    <Icon icon="icon-eye-view" size="sm"></Icon>
                  </IconButton>
                </Box>
              ) : null}
            </Column>
            <Divider />
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  icon="icon-catering-2"
                  size="lg"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  Catering added
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>
                    {passenger.length
                      ? `${cateringSelected.length}/${passenger.length}`
                      : '0'}
                  </b>
                </Typography>
              </Box>
              {cateringSelected.length ? (
                <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                  <IconButton color="inherit" onClick={onViewCatering}>
                    <Icon icon="icon-eye-view" size="sm"></Icon>
                  </IconButton>
                </Box>
              ) : null}
            </Column>
          </StaticWrapper>
        </ContentWrapper>
      ) : null}
    </>
  );
};

export default PassengerActionHeader;

import { Box, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  overflow: 'hidden',
  padding: '24px 32px',
  '.MuiGrid-item': {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const CateringWrapper = styled('ul', { name: 'CateringWrapper' })(
  ({ theme }) => ({
    margin: 0,
    maxWidth: '100%',
    paddingLeft: '20px'
  })
);

export const CateringOptionsWrapper = styled(Box, {
  name: 'CateringOptionsWrapper'
})(({ theme }) => ({
  padding: theme.spacing(2),
  '.MuiGrid-item': {
    display: 'flex',
    alignItems: 'flex-start'
  }
}));
